import { request, noTimeOutReq } from "@/utils/request.js";

// 台账分页(已修改)
// export function getMaterialAccountInfoApi(pageIndex, pageSize, data) {
//   return request({
//     url: `/eqpt/stock/consume/material/page/${pageIndex}/${pageSize}/list`,
//     method: "post",
//     data,
//   });
// }
export function getMaterialAccountInfoApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/category/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 仪器耗材登记(已修改)
export function addStockEquipmentApi(data) {
  return request({
    url: `/eqpt/stock/category/add`,
    method: "post",
    data,
  });
}

// 仪器耗材删除
export function deleteStockEquipmentApi(stockCategoryId) {
  return request({
    url: `/eqpt/stock/category/delete/${stockCategoryId}`,
    method: "post",
  });
}

// 仪器耗材库存详情数据(已修改)
export function getStockEquipmentDetailApi(data) {
  return request({
    url: `/eqpt/stock/consume/material/get/${data.get("sysOrgSchoolId")}/${data.get("sysExpConsumeMaterialId")}`,
    method: "post",
    data,
  });
}

// 点击添加的仪器耗材显示基本数据
export function getMaterialBaseInfoApi(sysExpConsumeMaterialId) {
  return request({
    url: `/equipment/stock/get/material/detail`,
    method: "post",
    params: {
      sysExpConsumeMaterialId,
    },
  });
}

// 仪器耗材修改(已修改)
export function updateStockEquipmentApi(data) {
  return request({
    url: `/eqpt/stock/category/update`,
    method: "post",
    data,
  });
}

// 获取某个仪器耗材记录
export function getMaterialRecordDetailApi(sysOrgSchoolId,sysExpConsumeMaterialId) {
  return request({
    url: `/eqpt/stock/record/list/`+sysOrgSchoolId+"/"+sysExpConsumeMaterialId,
    method: "post",
    params: {
      
    },
  });
}

// 查询记录列表
export function getDangerMaterialRecordListApi(eqptStockId) {
  return request({
    url: `/mini/stream/list/record/${eqptStockId}`,
    method: "post",
  });
}

// 获取【仪器耗材】概览
export function getMaterialSummaryApi(sysOrgSchoolId) {
  return request({
    url: `/eqpt/stock/consume/material/summary/${sysOrgSchoolId}`,
    method: "post",
  });
}

// 根据【仪器耗材ID】获取每个位置的数量
export function getMaterialLocationApi(sysExpConsumeMaterialId, data) {
  return request({
    url: `/eqpt/stock/consume/material/num/${sysExpConsumeMaterialId}`,
    method: "post",
    data,
  });
}

// 获取某个仪器耗材记录
export function getMaterialInOutStockRecordApi(sysOrgSchoolId,sysExpConsumeMaterialId) {
  return request({
    url: `/eqpt/stock/record/list/`+sysOrgSchoolId+"/"+sysExpConsumeMaterialId,
    method: "post",
    params: {
      
    },
  });
}
