import { request, noTimeOutReq } from '@/utils/request.js'

// 上传多个文件,注意文件类型要统一 同步
export function fileMultiple (data) {
  return noTimeOutReq({
    url: `/function/oss/upload/multiple/file`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
// 上传单个文件 同步
export function fileSingle (data) {
  return noTimeOutReq({
    url: `/function/oss/upload/single/file`,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  })
}
