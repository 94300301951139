import { request, noTimeOutReq } from "@/utils/request.js";

//新增【功能室】
export function addSchoolRoomApi(data) {
  return request({
    url: `/sys/org/school/room/add`,
    method: "post",
    data,
  });
}

// 删除【功能室】,慎用
export function deleteSchoolRoomApi(data) {
  return request({
    url: `/sys/org/school/room/delete`,
    method: "post",
    data,
  });
}

// 获取【功能室】详情
export function getSchoolRoomDetailApi(sysOrgSchoolRoomId) {
  return request({
    url: `/sys/org/school/room/get`,
    method: "post",
    params: {
      sysOrgSchoolRoomId,
    },
  });
}

// 展示所有【功能室】
export function getSchoolRoomListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/org/school/room/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 修改【功能室】
export function updateSchoolRoomApi(data) {
  return request({
    url: `/sys/org/school/room/update`,
    method: "post",
    data,
  });
}

// 展示某个学校内的全部功能室
export function getAllSchoolRoomListApi(sysOrgSchoolId) {
  return request({
    url: `/sys/org/school/room/${sysOrgSchoolId}/list`,
    method: "post",
  });
}

// 列表【功能室设备】
export function getDeviceListApi(data) {
  return request({
    url: `/sys/org/school/room/device/list`,
    method: "post",
    data,
  });
}

// 新增【功能室设备】
export function addDeviceApi(data) {
  return request({
    url: `/sys/org/school/room/device/add`,
    method: "post",
    data,
  });
}

// 删除【功能室设备】, 慎用
export function deleteDeviceApi(data) {
  return request({
    url: `/sys/org/school/room/device/delete`,
    method: "post",
    data,
  });
}

// 更新【功能室设备】
export function updateDeviceApi(data) {
  return request({
    url: `/sys/org/school/room/device/update`,
    method: "post",
    data,
  });
}

// 条件展示所有用户-所有
export function getAdminListApi(data) {
  return request({
    url: `/user/admin/user/query/list`,
    method: "post",
    data,
  });
}

// 展示某个学校下面所有【功能室】,分页
export function getSchoolRoomPageListApi(pageIndex, pageSize, data) {
  return request({
    url: `/sys/org/school/room/school/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}
