import { request, noTimeOutReq } from "@/utils/request.js";

// 出入库操作数据页
// export function getOutInLibListInfoApi(pageIndex, pageSize, data) {
//   return request({
//     url: `/equipment/stock/inout/page/${pageIndex}/${pageSize}/list`,
//     method: "post",
//     data,
//   });
// }

// 出入库登记
// export function addOutInLibInfoApi(data) {
//   return request({
//     url: `/equipment/stock/inout/operate`,
//     method: "post",
//     data,
//   });
// }

// 分页展示所有【入库单清单数据】
export function getInStockManageListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/in/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 仪器耗材入库
export function addInStockManageApi(data) {
  return request({
    url: `/eqpt/stock/in/add`,
    method: "post",
    data,
  });
}

// 获取【入库单】详情
export function getInStockListDetailApi(stockInId) {
  return request({
    url: `/eqpt/stock/in/get`,
    method: "post",
    params: {
      stockInId,
    },
  });
}

// 分页展示所有【入库单总账】
export function getInStockAccountListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/in/all/detail/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 展示所有【入库单清单数据】
export function getInStockMaterialData(stockInId) {
  return request({
    url: `/eqpt/stock/in/all/detail/list`,
    method: "post",
    params: {
      stockInId,
    },
  });
}

// 仪器耗材出库
export function addOutStockManageApi(data) {
  return request({
    url: `/eqpt/stock/out/add`,
    method: "post",
    data,
  });
}

// 分页展示所有【出库单清单台账数据】
export function getOutStockAccountListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/out/all/detail/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 获取【出库单】详情
export function getOutStockListDetailApi(stockOutId) {
  return request({
    url: `/eqpt/stock/out/get`,
    method: "post",
    params: {
      stockOutId,
    },
  });
}
// 展示所有【入库单清单数据】
export function getOutStockMaterialData(stockOutId) {
  return request({
    url: `/eqpt/stock/out/all/detail/list`,
    method: "post",
    params: {
      stockOutId,
    },
  });
}
// 分页展示所有【出库单】
export function getOutStockManageListApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/out/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}
